import React, { useState } from 'react';
import classNames from 'classnames';
import { Button } from 'components/Button';
import ProgressBar from './ProgressBar/ProgressBar';
import * as Collapsible from '@radix-ui/react-collapsible';
import styles from './Program.module.scss';
import { PlayIcon } from './PlayIcon/PlayIcon';
import { useTranslation } from 'hooks/useTranslation';
import { Card } from '@yleisradio/areena-types';
import { findLabel } from 'utils/card';
import { Marker } from './markers';
import ChevronRight from 'assets/chevronRight.svg';
import {
  ProgramControl,
  DisabledProgramControl,
} from './ProgramControl/ProgramControl';
import { determineBroadcastStatus } from '../utils';
import { getControlKey } from 'utils/control';

interface ProgramProps {
  program: Card;
  currentTime: number;
}

export const Program = ({ program, currentTime }: ProgramProps) => {
  const [infoOpen, setInfoOpen] = useState(false);
  const { title, description = '', labels = [], pointer } = program;
  const t = useTranslation();

  const getControls = (isActiveProgram: boolean) => {
    const controls = [];
    if (pointer) {
      controls.push(
        <Button
          key="program-page"
          text={t('linkToProgramPage')}
          iconRight={ChevronRight}
          pointer={pointer}
          variant="text"
          padding="none"
          theme={isActiveProgram ? 'light' : undefined}
        />
      );
    }

    if (program.controls) {
      controls.push(
        ...program.controls.map((control) => {
          return (
            <ProgramControl key={getControlKey(control)} control={control} />
          );
        })
      );
    }

    if (getOndemandStatus() === 'upcoming') {
      const tKey =
        findLabel(labels, 'mediaType')?.raw === 'audio'
          ? 'programOndemandAudioUpcoming'
          : 'programOndemandVideoUpcoming';

      controls.push(
        <DisabledProgramControl key="ondemand-upcoming" label={t(tKey)} />
      );
    }

    if (getOndemandStatus() === 'norights') {
      const tKey =
        findLabel(labels, 'mediaType')?.raw === 'audio'
          ? 'programAudioNoRights'
          : 'programVideoNoRights';

      controls.push(
        <em key="ondemand-norights" className={styles.noRights}>
          {t(tKey)}
        </em>
      );
    }

    return controls.length > 0 ? (
      <div className={styles.controlsContainer}>{controls}</div>
    ) : null;
  };

  const timeElement = () => {
    const startDateLabel = findLabel(labels, 'broadcastStartDate');

    return startDateLabel && startDateLabel.raw && startDateLabel.formatted ? (
      <time dateTime={startDateLabel.raw} className={styles.programTime}>
        {startDateLabel.formatted}
      </time>
    ) : null;
  };

  const areenaStatusIconElement = () => {
    const ondemandStatus = getOndemandStatus();

    if (ondemandStatus === 'current' || ondemandStatus === 'upcoming') {
      return (
        <PlayIcon
          aria-label={
            ondemandStatus === 'current'
              ? t('availableInAreena')
              : t('upcomingInAreena')
          }
          active={ondemandStatus === 'current'}
        />
      );
    }

    return null;
  };

  const videoFormatElement = () => {
    const videoFormat = findLabel(labels, 'videoFormat');

    if (videoFormat?.formatted) return <Marker>{videoFormat.formatted}</Marker>;
    return null;
  };

  const highlightElement = () => {
    const highlight = findLabel(labels, 'highlight');

    if (highlight?.formatted) {
      return <Marker>{highlight.formatted}</Marker>;
    }

    return null;
  };

  const hardOfHearingSubtitlingElement = () => {
    const subtitling = findLabel(labels, 'hardOfHearingSubtitling');

    if (subtitling)
      return <span className={styles.infoMarker}>{subtitling.formatted}</span>;
    return null;
  };

  const durationElement = () => {
    const duration = findLabel(labels, 'duration');

    if (duration)
      return <span className={styles.infoMarker}>{duration.formatted}</span>;
    return null;
  };

  const handleClick = () => setInfoOpen(!infoOpen);

  const getProgramStartTimestamp = () =>
    Date.parse(findLabel(labels, 'broadcastStartDate')?.raw || '');
  const getProgramEndTimestamp = () =>
    Date.parse(findLabel(labels, 'broadcastEndDate')?.raw || '');
  const getOndemandStatus = () => findLabel(labels, 'ondemandStatus')?.raw;

  const isActiveProgram =
    determineBroadcastStatus(program, currentTime) === 'current' || infoOpen;

  return (
    <Collapsible.Root
      role="group"
      className={classNames(
        styles.programRoot,
        isActiveProgram && styles.programActive
      )}
      open={infoOpen}
    >
      <header>
        <Collapsible.Trigger asChild>
          <button
            className={classNames(
              styles.programHeader,
              isActiveProgram && styles.programHeaderActive
            )}
            onClick={handleClick}
          >
            <div className={styles.programTitleRow}>
              {timeElement()}
              {areenaStatusIconElement()}
              <div>
                <h3 className={styles.programTitle}>{title}</h3>
                {highlightElement()}
              </div>
            </div>
            {determineBroadcastStatus(program, currentTime) === 'current' && (
              <ProgressBar
                start={getProgramStartTimestamp()}
                end={getProgramEndTimestamp()}
                current={currentTime}
              />
            )}
          </button>
        </Collapsible.Trigger>
      </header>

      <Collapsible.Content className={styles.programInfo}>
        {description} <span>{hardOfHearingSubtitlingElement()}</span>{' '}
        <span>{durationElement()}</span> <span>{videoFormatElement()}</span>
        {getControls(isActiveProgram)}
      </Collapsible.Content>
    </Collapsible.Root>
  );
};
